<template>
	<div id="home">
		<div id="nav">
			<router-link to="/">HOME</router-link>
		</div>
		<div style="">
			<van-cell title="购买订阅" is-link to="/buy" icon="shopping-cart-o" />
			<van-cell title="订单记录" is-link to="/OrderList" icon="bag-o" />
			<van-cell title="节点列表" is-link icon="points" />
			<van-cell title="在线用户" is-link icon="friends-o" />
		</div>
	</div>
</template>

<style>
	.custom-title {
		margin-right: 4px;
		vertical-align: middle;
	}
	#home{
		text-align: center;
	}
	.search-icon {
		font-size: 16px;
		line-height: inherit;
	}
</style>
<script>
	
	export default {
		methods:{
			function(){
			}
		}
	}
</script>
